<template>
  <div class="chart" :style="{height:'300px',width:'100%'}" />
</template>

<script>
import * as echarts from 'echarts'
import { request } from '@/assets/js/http.js'
require('echarts/theme/macarons')

export default {
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.chart = echarts.init(this.$el)
    this.$nextTick(() => {
      this.initChart()
    })
  },
  methods: {
    initChart () {
      request('/api/order/order/getBrokenInfo', 'get').then((res) => {
        if (res.code === '200') {
          this.chart.setOption({
            tooltip: {
              trigger: 'item',
              backgroundColor: 'rgb(31, 35, 41)',
              textStyle: { color: 'rgb(255, 255, 255)' }
            },
            legend: {
              bottom: 0,
              left: 'center',
              selectedMode: false
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: res.data.monthList
            },
            yAxis: {
              type: 'value',
              axisLabel: {
                margin: 2
              }
            },
            grid: {
              left: 45
            },
            series: {
              type: 'line',
              name: '合同签约',
              data: res.data.numberList,
              itemStyle: {
                color: 'rgb(48, 189, 130)'
              }
            }
          })
        }
      })
    }
  }
}
</script>
